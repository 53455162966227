.ol-scale-line {
    background: white;
    filter: drop-shadow(0px 2px 4px #808F99);
    padding: 4px;
    border-radius: 4px;
    bottom: 30px;
    left: 20px;
}

.ol-scale-line-inner {
    color: black;
    border: 2px solid black;
    border-top: none;
}

.ol-mouse-position {
    top: initial;
    pointer-events: auto;
    bottom: 30px;
    left: calc(50% - 80px);
    width: 160px;
    text-align: center;
    background-color: rgba(255,255,255,0.6);
    border-radius: 4px;
}

.ol-zoom {
    background-color: white;
    filter: drop-shadow(0px 2px 4px #808F99);
    top: 20px;
    left: 20px;
}

.ol-zoom:hover {
    background-color: white;
}

.ol-zoom .ol-zoom-in {
    background-color: #DAE0E4;
    color: black;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.ol-zoom .ol-zoom-in:focus {
    background-color: #DAE0E4;
}

.ol-zoom .ol-zoom-in:hover {
    background-color: #CAD0D4;
}

.ol-zoom .ol-zoom-out {
    background-color: #DAE0E4;
    color: black;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.ol-zoom .ol-zoom-out:focus {
    background-color: #DAE0E4;
}

.ol-zoom .ol-zoom-out:hover {
    background-color: #CAD0D4;
}

.ol-zoom-extent {
    background-color: white;
    filter: drop-shadow(0px 2px 4px #808F99);
    top: 80px;
    left: 20px;
}

.ol-zoom-extent button {
    background-color: #DAE0E4;
    color: black;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.ol-zoom-extent:hover {
    background-color: white;
}

.ol-zoom-extent button:focus {
    background-color: #DAE0E4;
}

.ol-zoom-extent button:hover {
    background-color: #CAD0D4;
}
